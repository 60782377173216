.error {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: cenner;
  position: fixed;
  top: 0;
  left: 0;
  height: auto;
  width: 70%;
  border-radius: 5px;
  text-align: center;
  margin: calc(50vh - 75px) 15%;
  background: rgba(#000, .75);
  color: #fff;
  padding: 15px;
  z-index: 9;
  opacity: 0;
  transition: opacity .4s ease;
  button {
    background-color: #ffdf7c;
    border-radius: 5px;
    color: #000;
    padding: 5px;
    margin: 10px 10px 0;
    border: none;
  }
  &.visible {
    opacity: 1;
  }
}