@import url(/reset-css/reset.css);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* COLORS */
/* PLAYER CONTROLS */
.home {
  padding: 20px 40px;
  background-color: #DB0007; }
  .home:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-image: url(/static/media/bg-frame.1381f96e.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: inset 0 0 45vw 40vw #DB0007; }
  .home .home-description {
    color: #fff;
    position: relative;
    margin-bottom: 15px; }
  .home .card {
    margin-top: 40px;
    padding-bottom: 5%;
    background: #fff;
    border-radius: 5px;
    width: 100%;
    box-shadow: 0px 0px 15px 0px rgba(38, 38, 38, 0.28);
    color: #004233;
    position: relative;
    z-index: 1; }
    .home .card__img {
      width: 97%; }
    .home .card__icon {
      margin: -20px 0px 0 -25px;
      width: 70px;
      position: absolute;
      top: 0;
      left: 0; }
    .home .card span, .home .card strong, .home .card p, .home .card em {
      padding: 0 5%;
      display: block; }
    .home .card__number {
      margin-top: calc(4% - 1.15em); }
    .home .card p {
      font-size: .9em; }
    .home .card em {
      font-size: .85em;
      color: #969696; }
    .home .card.standard-cover {
      text-align: center; }
      .home .card.standard-cover span, .home .card.standard-cover strong, .home .card.standard-cover p, .home .card.standard-cover em {
        text-align: left; }
      .home .card.standard-cover .card__img {
        margin-top: 5%;
        width: 90%; }
      .home .card.standard-cover .card__number {
        margin-top: 0;
        text-align: left; }
  .home .img-box {
    position: relative;
    margin: 15px auto; }
    .home .img-box.logo {
      max-width: 78%;
      margin-top: 10vh; }
    .home .img-box.books {
      max-width: 100%; }
    .home .img-box img {
      width: 100%;
      height: auto;
      vertical-align: bottom; }

/* COLORS */
/* PLAYER CONTROLS */
.book {
  background-image: url(/static/media/bg-frame.1381f96e.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #DB0007;
  padding: 30px 11vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: inset 0 0 45vw 30vw #DB0007; }
  .book__heading {
    color: #004233;
    background-color: #fff;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 20px; }
    .book__heading p {
      font-size: .95em; }
  .book__cover {
    width: 100vw;
    height: 50vh;
    margin-left: -11vw; }
    .book__cover-bg {
      height: 50vh;
      width: 78vw;
      margin-left: 11vw;
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 5px; }
    .book__cover-chars {
      height: 50vh;
      position: relative;
      top: -50vh;
      width: 100vw;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%; }
  .book-1 .book__cover-bg {
    background-image: url(/static/media/bg1.20425495.png);
    background-position: bottom; }
  .book-1 .book__cover-chars {
    background-image: url(/static/media/chars1.5b7351ac.png);
    background-position: bottom; }
  .book-2 .book__cover-bg {
    background-size: 110%;
    background-image: url(/static/media/bg2.9c8e67af.png);
    background-position: bottom; }
  .book-2 .book__cover-chars {
    background-size: 110%;
    background-position: bottom right;
    background-image: url(/static/media/chars2.5395c34f.png); }
  .book-3 .book__cover-bg {
    background-size: 110%;
    background-image: url(/static/media/book3cover_preview.77293a39.png);
    background-position: bottom; }
  .book-4 .book__cover-bg {
    background-size: 110%;
    background-image: url(/static/media/book4cover_preview.f3e68e4f.png);
    background-position: bottom; }
  .book-5 .book__cover-bg {
    background-size: auto 100%;
    background-image: url(/static/media/book5player.f68ef182.png);
    background-position: bottom; }
  .book-6 .book__cover-bg {
    background-size: auto 100%;
    background-image: url(/static/media/book6player.f80c623a.png);
    background-position: bottom; }
  .book-6 .book__heading p {
    white-space: pre-line; }
  .book-7 .book__cover-bg {
    background-size: auto 100%;
    background-image: url(/static/media/book7_wetenschapper.263a69b1.png);
    background-position: bottom; }
  .book-8 .book__cover-bg {
    background-size: auto 100%;
    background-image: url(/static/media/book8_sportkampioen.a41dcdd6.png);
    background-position: bottom; }
  .book-9 .book__cover-bg {
    background-size: auto 100%;
    background-image: url(/static/media/book9_dappere-avonturier.47e86da5.png);
    background-position: bottom; }
  .book-10 .book__cover-bg {
    background-size: auto 100%;
    background-image: url(/static/media/book10_innovatieve-ontwerpen.85c84241.png);
    background-position: bottom; }
  .book-11 .book__cover-bg {
    background-size: auto 100%;
    background-image: url(/static/media/book11_inspirerende-leider.70bce4bf.png);
    background-position: bottom; }
  .book-12 .book__cover-bg {
    background-size: auto 100%;
    background-image: url(/static/media/book12_fantasierijke-maker.d30000b6.png);
    background-position: bottom; }
  .book-13 .book__cover-bg {
    background-size: auto 100%;
    background-image: url(/static/media/book13_happystories.1d9ade27.png);
    background-position: bottom; }
  .book__controls-wrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }
  .book__progress {
    background-color: #ffdf7c;
    width: 100%;
    border-radius: 3px;
    height: 12px; }
  .book__progress-complete {
    background-color: #ffbb0d;
    height: 12px;
    border-radius: 3px;
    width: 20%; }
  .book__progress-icon {
    width: 30px;
    position: relative;
    top: -20px;
    left: -15px;
    margin-left: 0; }
  .book__progress-value {
    width: 50%;
    color: #ffbb0d;
    margin-top: 10px;
    font-size: .9em; }
    .book__progress-value:first-of-type {
      text-align: left; }
    .book__progress-value:last-of-type {
      text-align: right; }
  .book__control {
    width: 1.8em; }
    .book__control.prev {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      margin-left: 5%; }
    .book__control.play {
      width: 4em; }
    .book__control.next {
      margin-right: 5%; }
    .book__control.disabled {
      -webkit-filter: saturate(0.6) brightness(1.15) contrast(0.9);
              filter: saturate(0.6) brightness(1.15) contrast(0.9); }

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3; }
  .loader img {
    width: 150px; }

.error {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: cenner;
  position: fixed;
  top: 0;
  left: 0;
  height: auto;
  width: 70%;
  border-radius: 5px;
  text-align: center;
  margin: calc(50vh - 75px) 15%;
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 15px;
  z-index: 9;
  opacity: 0;
  transition: opacity .4s ease; }
  .error button {
    background-color: #ffdf7c;
    border-radius: 5px;
    color: #000;
    padding: 5px;
    margin: 10px 10px 0;
    border: none; }
  .error.visible {
    opacity: 1; }

@font-face {
  font-family: 'Speedee';
  font-weight: 400;
  src: url(/static/media/Speedee_W_Rg.91796517.woff) format("woff"); }

@font-face {
  font-family: 'Speedee';
  font-weight: 700;
  src: url(/static/media/Speedee_W_Bd.ed8982a4.woff) format("woff"); }

@font-face {
  font-family: 'Speedee';
  font-weight: 400;
  font-style: italic;
  src: url(/static/media/Speedee_It.d6abeb6e.ttf) format("truetype"); }

/* COLORS */
/* PLAYER CONTROLS */
body, html {
  overflow-x: hidden;
  width: 100vw;
  -webkit-overflow-scrolling: none;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Firefox */
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }

* {
  box-sizing: border-box; }

.App {
  font-size: 4vmin;
  font-family: 'Speedee', sans-serif;
  color: #000;
  min-width: 320px; }
  .App section {
    width: 100%;
    min-height: 100vh; }
  .App h1, .App h2, .App span.number {
    font-size: 1.5em;
    font-weight: 700;
    color: #ffbb0d; }
  .App h1, .App h2 {
    line-height: 1.2em;
    margin: 0 0 5px;
    position: relative; }
  .App p {
    margin: 0;
    line-height: 1.2em; }
  .App strong {
    font-weight: 700; }
  .App em {
    font-style: italic;
    font-size: .85em;
    color: #969696; }

