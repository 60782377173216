@import '../_variables.scss';

.home {
  padding: 20px 40px;
  background-color: $red;
  
  &:before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-image: url('../assets/bg-frame.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: inset 0 0 45vw 40vw $red;
  }
  
  .home-description{
    color: $white;
    position: relative;
    margin-bottom: 15px;
  }
  
  .card {
    margin-top: 40px;
    padding-bottom:  5%;
    background: $white;
    border-radius: 5px;
    width: 100%;
    box-shadow: 0px 0px 15px 0px rgba(38, 38, 38, .28);
    color: $green;
    position: relative;
    z-index: 1;
    &__img {
      width: 97%;
    }
    &__icon {
      margin: -20px 0px 0 -25px;
      width: 70px;
      position: absolute;
      top: 0;
      left: 0;
    }
    span, strong, p, em {
      padding: 0 5%;
      display: block;
    }
    &__number {
      margin-top: calc(4% - 1.15em);
    }
    p {
      font-size: .9em;
    }
    em {
      font-size: .85em;
      color: $grey;
    }
    
    &.standard-cover{
      text-align: center;
  
      span, strong, p, em{
        text-align: left;
      }
  
      .card{
        &__img {
          margin-top: 5%;
          width: 90%;
        }
        &__number{
          margin-top: 0;
          text-align: left;
        }
      }
    }
  }
  
  .img-box{
    position: relative;
    margin: 15px auto;
    
    &.logo{
      max-width: 78%;
      margin-top: 10vh;
    }
    
    &.books{
      max-width: 100%;
    }
    
    img{
      width: 100%;
      height: auto;
      vertical-align: bottom;
    }
  }
}