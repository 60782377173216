@import '../variables.scss';

.book {
  background-image: url('../assets/bg-frame.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: $red;
  padding: 30px 11vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: inset 0 0 45vw 30vw $red;
  &__heading {
    color: $green;
    background-color: $white;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 20px;
    p {
      font-size: .95em;
    }
  }
  &__cover {
    width: 100vw;
    height: 50vh;
    margin-left: -11vw;
    &-bg {
      height: 50vh;
      width: 78vw;
      margin-left: 11vw;
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 5px;
    }
    &-chars {
      height: 50vh;
      position: relative;
      top: -50vh;
      width: 100vw;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }
  &-1 {
    .book__cover {
      &-bg {
        background-image: url('../assets/bg1.png');
        background-position: bottom;
      }
      &-chars {
        background-image: url('../assets/chars1.png');
        background-position: bottom;
      }
    }
  }
  &-2 {
    .book__cover {
      &-bg {
        background-size: 110%;
        background-image: url('../assets/bg2.png');
        background-position: bottom;
      }
      &-chars {
        background-size: 110%;
        background-position: bottom right;
        background-image: url('../assets/chars2.png');
      }
    }
  }
  &-3 {
    .book__cover {
      &-bg {
        background-size: 110%;
        background-image: url('../assets/book3cover_preview.png');
        background-position: bottom;
      }
    }
  }
  &-4 {
    .book__cover {
      &-bg {
        background-size: 110%;
        background-image: url('../assets/book4cover_preview.png');
        background-position: bottom;
      }
    }
  }
  &-5 {
    .book__cover {
      &-bg {
        background-size: auto 100%;
        background-image: url('../assets/book5player.png');
        background-position: bottom;
      }
    }
  }
  &-6 {
    .book__cover {
      &-bg {
        background-size: auto 100%;
        background-image: url('../assets/book6player.png');
        background-position: bottom;
      }
    }
    .book__heading{
      p{
        white-space: pre-line;
      }
    }
  }
  
  &-7 {
    .book__cover {
      &-bg {
        background-size: auto 100%;
        background-image: url('../assets/book7_wetenschapper.png');
        background-position: bottom;
      }
    }
  }
  
  &-8 {
    .book__cover {
      &-bg {
        background-size: auto 100%;
        background-image: url('../assets/book8_sportkampioen.png');
        background-position: bottom;
      }
    }
  }
  
  &-9 {
    .book__cover {
      &-bg {
        background-size: auto 100%;
        background-image: url('../assets/book9_dappere-avonturier.png');
        background-position: bottom;
      }
    }
  }
  
  &-10 {
    .book__cover {
      &-bg {
        background-size: auto 100%;
        background-image: url('../assets/book10_innovatieve-ontwerpen.png');
        background-position: bottom;
      }
    }
  }
  
  &-11 {
    .book__cover {
      &-bg {
        background-size: auto 100%;
        background-image: url('../assets/book11_inspirerende-leider.png');
        background-position: bottom;
      }
    }
  }
  
  &-12 {
    .book__cover {
      &-bg {
        background-size: auto 100%;
        background-image: url('../assets/book12_fantasierijke-maker.png');
        background-position: bottom;
      }
    }
  }
  
  &-13 {
    .book__cover {
      &-bg {
        background-size: auto 100%;
        background-image: url('../assets/book13_happystories.png');
        background-position: bottom;
      }
    }
  }
  
  &__controls-wrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  &__progress {
    background-color: $progress;
    width: 100%;
    border-radius: 3px;
    height: 12px;
  }
  &__progress-complete {
    background-color: $yellow;
    height: 12px;
    border-radius: 3px;
    width: 20%;
  }
  &__progress-icon {
    width: 30px;
    position: relative;
    top: -20px;
    left: -15px;
    margin-left: 0;
  }
  &__progress-value {
    width: 50%;
    color: $yellow;
    margin-top: 10px;
    font-size: .9em;
    &:first-of-type {
      text-align: left;
    }
    &:last-of-type {
      text-align: right;
    }
  }
  &__control {
    width: 1.8em;
    &.prev {
      transform: rotate(180deg);
      margin-left: 5%;
    }
    &.play {
      width: 4em;
    }
    &.next {
      margin-right: 5%;
    }
    &.disabled {
      filter: saturate(.6) brightness(1.15) contrast(.9);
    }
  }
}