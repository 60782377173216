@import '~/reset-css/reset.css';

@import '_fonts.scss';
@import '_variables.scss';

body, html {
  overflow-x: hidden;
  width: 100vw;
  -webkit-overflow-scrolling: none;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

* {
  box-sizing: border-box;
}

.App {
  font-size: 4vmin;
  font-family: 'Speedee', sans-serif;
  color: $black;
  min-width: 320px;

  section {
    width: 100%;
    min-height: 100vh;
  }

  h1, h2, span.number {
    font-size: 1.5em;
    font-weight: 700;
    color: $yellow;
  }

  h1, h2 {
    line-height: 1.2em;
    margin: 0 0 5px;
    position: relative;
  }

  p {
    margin: 0;
    line-height: 1.2em;
  }

  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
    font-size: .85em;
    color: $grey;
  }
}