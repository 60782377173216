/* COLORS */

$white: #fff;                  // home bg, card bg
$black: #000;                  // home text at top
$yellow: #ffbb0d;              // home heading, player inactive navigation button
$green: #004233;               // book page bg, text
$grey: #969696;                // text (book author)
$red: #DB0007;
/* PLAYER CONTROLS */

$progress: #ffdf7c;            // progress bar - not played
$pl_inactive_button: #ffdf7c;  // player inactive navigation button
